import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/preview-1'
  },
  {
    path: '/preview-1',
    name: 'Preview1',
    component: () => import(/* webpackChunkName: "pages" */ '../views/Preview1.vue'),
    children: [
        {
            path: '',
            name: 'Preview1Index',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Preview1/Index.vue'),
        },
        {
            path: ':id',
            props: true,
            name: 'Preview1Show',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Preview1/Show.vue'),
        }
    ]
  },
  {
    path: '/preview-2',
    name: 'Preview2',
    component: () => import(/* webpackChunkName: "pages" */ '../views/Preview2.vue'),
    children: [
        {
            path: '',
            name: 'Preview2Index',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Preview2/Index.vue'),
        },
        {
            path: ':id',
            props: true,
            name: 'Preview2Show',
            component: () => import(/* webpackChunkName: "pages" */ '../views/Preview2/Show.vue'),
        }
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
